import React, { useEffect, useState } from 'react';
import { Carousel, Button, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const products = [
    {
        id: 1,
        title: 'Mango',
        description: 'Homemade Trendy Pickle',
        price: 199,
        imageMain: './assets/mango/mango-1.jpg',
        images: [
          "./assets/mango/1-1kg.jpg",
          "./assets/mango/mango-2.jpg",
          "./assets/mango/mango-250.jpg"
        ]
      },
      {
        id: 2,
        title: 'Lemon',
        description: 'Homemade Trendy Pickle',
        price: 199,
        imageMain: './assets/lemon/lemon-1.jpg',
        images: [
          './assets/lemon/lemon-2.jpg',
          './assets/lemon/lemon-1.jpg',
          './assets/lemon/lemon-2.jpg',
        ],
      },
    {
      id: 3,
      title: 'Product Name',
      description: 'Product description',
      price: 199,
      imageMain: '',
      images: [
        'image1.jpg',
        'image2.jpg',
        'image3.jpg',
      ],
    },
    {
      id: 4,
      title: 'Product Name',
      description: 'Product description',
      price: 199,
      imageMain: '',
      images: [
        'image1.jpg',
        'image2.jpg',
        'image3.jpg',
      ],
    },
    {
      id: 5,
      title: 'Product Name',
      description: 'Product description',
      price: 199,
      imageMain: '',
      images: [
        'image1.jpg',
        'image2.jpg',
        'image3.jpg',
      ],
    },
    {
      id: 6,
      title: 'Product Name',
      description: 'Product description',
      price: 199,
      imageMain: '',
      images: [
        'image1.jpg',
        'image2.jpg',
        'image3.jpg',
      ],
    },
    // ... more products
  ];


const ProductDetailPage = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const { id } = useParams();
    const [product, setProduct] = useState(null);

  useEffect(() => {
    const foundProduct = products.find(p => p.id === parseInt(id));
    setProduct(foundProduct);
  }, [id]);

  if (!product) {
    return <div>Product not found</div>;
  }


  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <div className="product-detail">
      <Row>
        <Col md={6}>
          <Carousel activeIndex={activeIndex} onSelect={handleSelect}>
            {product.images.map((image, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={"./assets/mango/mango-2.jpg"}
                  alt={`Product ${product.id} - Image ${image}`}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
        <Col md={6}>
          <h2>{product.title}</h2>
          <p>{product.description}</p>
          <div className="variant-selector">
            {/* Add variant selection options here, e.g., size, color, etc. */}
            <p>Select Variant:</p>
            <div className="variant-options">
              {/* Render variant options dynamically based on product data */}
              <button className="variant-option">Variant 1</button>
              <button className="variant-option">Variant 2</button>
            </div>
          </div>
          <p>Price: ₹{product.price}</p>
          <Button variant="primary">Add to Cart</Button>
        </Col>
      </Row>
    </div>
  );
};

export default ProductDetailPage;