import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function AboutPage() {
  return (
    <div className="about-us">
      <Container>
        <Row>
          <Col md={12} className='justify-center mt-4 mb-4'>
            <h1>A Taste of Tradition from Mangalagiri</h1>
            <p>
              Discover the authentic taste of India with Mangalagiri Pickles. Our vegetarian and non-vegetarian pickles are made with traditional recipes and the finest ingredients.
            </p>
          </Col>
        </Row>
        <Row>
          {/* Add more rows and columns for specific sections like "Our Story," "Our Mission," "Our Team," etc. */}
          <Col md={12} className='justify-center mt-4 mb-4'>
            <h2>Our Story</h2>
            <p>
            In the heart of Mangalagiri, where the air is fragrant with the spicy aroma of simmering spices, lies a legacy that spans generations. For over half a century, the Rekha's family has been crafting authentic Indian pickles, each jar a testament to their unwavering commitment to tradition and quality.
            </p>
            <p>
The story begins in the 1970s, when <b>Rekha Krishnarjuna Rao</b>, a culinary virtuoso, began experimenting with various pickling techniques. Inspired by age-old recipes and a passion for flavor, they perfected the art of infusing vegetables and fruits with a symphony of spices.</p>
<p>
As the years passed, the family's love for pickling grew, and they started sharing their creations with friends and neighbors. The positive response and growing demand encouraged them to expand their operation. Soon, their pickles became a local sensation, renowned for their unique blend of tangy, spicy, and sweet flavors.</p>
<p>
Today, the family business continues to thrive, with <b>Rekha Naresh Babu</b> carrying the torch of tradition. They have modernized their production process while staying true to their roots. Their pickles are still made using traditional methods, ensuring that each jar captures the essence of authentic Indian cuisine.</p>
<p>
From the meticulous selection of fresh ingredients to the precise blending of spices, every step of the pickle-making process is carefully monitored. The family's commitment to quality is evident in the rich, vibrant colors and tantalizing aromas of their pickles.</p>
<p>
Whether it's the tangy mango pickle, the fiery green chili pickle, or the aromatic mixed vegetable pickle, each jar is a masterpiece that evokes nostalgia and satisfies the taste buds. <b>The legacy of the Rekha'scontinues</b>  to inspire generations, preserving the authentic taste of India, one pickle at a time.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AboutPage;