import React from 'react';

const GoogleMapComponent = ({ latitude, longitude, markerUrl }) => {
  const mapUrl = `https://maps.google.com/maps?q=${latitude},${longitude}&z=15&output=embed`;

  return (
    <div>
      <iframe src={mapUrl} style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
      <a href={markerUrl} target="_blank" rel="noopener noreferrer">
      </a>
    </div>
  );
};

export default GoogleMapComponent;