import React, { useRef, useEffect } from 'react';
import './VideoPlayer.css'; 

const VideoPlayer = ({ videoSrc, title, description, videoSpeed }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.playbackRate = videoSpeed;
  }, [videoSpeed, videoRef]);

  const scrollBelow = () => {
    window.scrollTo({
      top: window.screen.height - 112,
      behavior: "smooth",
    });
  };

  return (
    <div className="video-container">
      <video autoPlay muted loop ref={videoRef}>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video-overlay">
        <h2 className="video-title">{title}</h2>
        <h4 className="video-description" style={{"color":"#f5f5f5"}}>{description}</h4>
        <button className='whiteButton' onClick={scrollBelow}>EXPLORE</button>
      </div>
    </div>
  );
};

export default VideoPlayer;