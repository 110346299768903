import React from 'react';
import ProductMenuPage from './ProductMenuPage';
import Footer from '../components/Footer';
import VideoPlayer from '../components/VideoPlayer';

function HomePage() {
  return (
    <section>
      <VideoPlayer
        videoSrc="./assets/mangalagiriPickles-Intro.mp4"
        title="Mangalagiri Pickles"
        description="Discover the Authentic Taste and Quality Pickles"
        videoSpeed={0.5} // Adjust the speed as needed
      />
      <ProductMenuPage />
    </section>
  );
}

export default HomePage;
