import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const ContactPage = () => {
  return (
    <Container className='justify-center mt-4 mb-4'>
      <Row className='mt-4 mb-4'>
          <h2>Contact Us</h2>
          <p>
          Discover the authentic taste of India with Mangalagiri Pickles. Our vegetarian and non-vegetarian pickles are made with traditional recipes and the finest ingredients.
          </p>
          <p>
            <strong>Address:</strong> H.No.: 11, 450, NCC Rd, Mangalagiri, Andhra Pradesh 522503<br />
            <strong>Email:</strong> mangalagiripickles@gmail.com<br />
            <strong>Phone:</strong> +91 99490 96910
          </p>
          </Row>
          <Row className='mt-4 mb-4'>
          <h2>Send a Message</h2>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter your email" />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Enter your message" />
            </Form.Group>
            <Button variant="primary" className='mt-4 mb-4' type="submit">
              Submit
            </Button>
          </Form>
          </Row>
    </Container>
  );
};

export default ContactPage;