import React from 'react';
import ProductCard from '../components/ProductCard';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const products = [
  {
    id: 1,
    title: 'Mango',
    description: 'Homemade Trendy Pickle - 1Kg',
    price: 199,
    imageMain: './assets/mango/mango-1.jpg',
    images: [
      "./assets/mango/1-1kg.jpg",
      "./assets/mango/mango-2.jpg",
      "./assets/mango/mango-250.jpg"
    ]
  },
  {
    id: 2,
    title: 'Pandumirapa',
    description: 'Homemade Trendy Pickle - 1Kg',
    price: 199,
    imageMain: './assets/pandumirapa/pandumirapa-1.jpg',
    images: [
      './assets/pandumirapa/pandumirapa-1.jpg',
      'image2.jpg',
      'image3.jpg',
    ],
  },
  {
    id: 3,
    title: 'Tomato',
    description: 'Homemade Trendy Pickle - 1Kg',
    price: 199,
    imageMain: './assets/tomato/tomato-1.jpg',
    images: [
      './assets/tomato/tomato-1.jpg',
      'image2.jpg',
      'image3.jpg',
    ],
  },
  {
    id: 4,
    title: 'Lemon',
    description: 'Homemade Trendy Pickle - 1Kg',
    price: 199,
    imageMain: './assets/lemon/lemon-1.jpg',
    images: [
      './assets/lemon/lemon-2.jpg',
      './assets/lemon/lemon-1.jpg',
      './assets/lemon/lemon-2.jpg',
    ],
  },
  {
    id: 5,
    title: 'Allam',
    description: 'Homemade Trendy Pickle - 1Kg',
    price: 199,
    imageMain: './assets/allam/allam-1.jpg',
    images: [
      './assets/allam/allam-1.jpg',
      'image2.jpg',
      'image3.jpg',
    ],
  },
  {
    id: 6,
    title: 'Gongura',
    description: 'Homemade Trendy Pickle - 1Kg',
    price: 199,
    imageMain: './assets/gongura/gongura-1.jpg',
    images: [
      './assets/gongura/gongura-1.jpg',
      'image2.jpg',
      'image3.jpg',
    ],
  },
  {
    id: 7,
    title: 'Munaga',
    description: 'Homemade Trendy Pickle - 1Kg',
    price: 199,
    imageMain: './assets/munaga/munaga-1.jpg',
    images: [
      './assets/munaga/munaga-1.jpg',
      'image2.jpg',
      'image3.jpg',
    ],
  },
  {
    id: 8,
    title: 'Kakara',
    description: 'Homemade Trendy Pickle - 1Kg',
    price: 199,
    imageMain: './assets/kakara/kakara-1.jpg',
    images: [
      './assets/kakara/kakara-1.jpg',
      'image2.jpg',
      'image3.jpg',
    ],
  },
  {
    id: 9,
    title: 'Dondakaya',
    description: 'Homemade Trendy Pickle - 1Kg',
    price: 199,
    imageMain: './assets/donda/donda-1.jpg',
    images: [
      './assets/donda/donda-1.jpg',
      'image2.jpg',
      'image3.jpg',
    ],
  },
  {
    id: 10,
    title: 'Chicken',
    description: 'Homemade Trendy Pickle - 1Kg',
    price: 199,
    imageMain: './assets/chicken/chicken-1.jpg',
    images: [
      './assets/chicken/chicken-1.jpg',
      'image2.jpg',
      'image3.jpg',
    ],
  },
  {
    id: 11,
    title: 'Prawn',
    description: 'Homemade Trendy Pickle - 1Kg',
    price: 199,
    imageMain: './assets/prawn/prawn-1.jpg',
    images: [
      './assets/prawn/prawn-1.jpg',
      'image2.jpg',
      'image3.jpg',
    ],
  },
  {
    id: 12,
    title: 'Fish',
    description: 'Homemade Trendy Pickle - 1Kg',
    price: 199,
    imageMain: './assets/fish/fish-1.jpg',
    images: [
      './assets/fish/fish-1.jpg',
      'image2.jpg',
      'image3.jpg',
    ],
  },
  // ... more products
];

function ProductMenuPage() {
  return (
    <Container className='mt-4 mb-4'>
      <Row>
        {products.map((product, index) => (
          <Col md={4} key={index} className='mt-4 mb-4'>
            {/* <Link to={`/product/${product.id}`}> */}
            <ProductCard product={product} />
            {/* </Link> */}
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ProductMenuPage;