import React, { useState } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';

function MyNavbar() {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar bg="dark" variant="dark" fixed="top" expand="lg" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
      <Container>
        <Navbar.Brand href="/">Mangalagiri Pickles</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav >
            <Nav.Link href="home">Home</Nav.Link>
            {/* <NavDropdown title="Pickles" id="basic-nav-dropdown">
              <NavDropdown.Item href="action/3.1">Vegetarian</NavDropdown.Item>
              <NavDropdown.Item href="action/3.2">Wholesale</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="action/3.3">Non-Veg</NavDropdown.Item> 
            </NavDropdown>*/}
            <Nav.Link href="menu">Menu</Nav.Link>
            <Nav.Link href="about">About Us</Nav.Link>
            <Nav.Link href="contact">Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;