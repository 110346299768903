import React from 'react';

const WhatsAppChat = ({ phone, imageSrc, message }) => {
  const encodedMessage = encodeURIComponent(message);
  const whatsappUrl = `https://wa.me/${phone}?text=Hi there! Would like to place an order for ${encodedMessage} - 1Kg.`;
  
  return (
    <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
      <img src={imageSrc} alt="WhatsApp Chat" height="64px"/>
    </a>
  );
};

export default WhatsAppChat;