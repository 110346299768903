import { Container, Row, Col } from 'react-bootstrap';
import WhatsAppChat from './WhatsAppChat';
import GoogleMapComponent from './GoogleMapComponent';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter
} from "react-icons/fa";

function Footer() {
  const latitude = 16.4248462;
  const longitude = 80.5688028;
  const markerUrl = 'https://www.google.com/maps/place/Mangalagiri,+Andhra+Pradesh';
  
  return (
    <section>
    <footer className="footer bg-dark text-light">
      <Container>
        <br />
        <h5>Location</h5>
        <Row>
          <Col md={6} className='justify-center'>
            <div>
              <GoogleMapComponent
                latitude={latitude}
                longitude={longitude}
                markerUrl={markerUrl}
              />
            </div>
          </Col>
          <Col md={3}>
            <h5>For any queries!</h5>
            <WhatsAppChat phone="9949096910" imageSrc="./assets/whatsapp.webp"/>
            <p className="text-">Copyright &copy;{new Date().getFullYear()} Mangalagiri Pickles. All Rights Reserved.</p>
          </Col>
          <Col md={3}>
            <h5>We are social</h5>
              <a
                href="https://www.facebook.com/"
                aria-label="Facebook"
                target="_blank"
              >
                <FaFacebook style={{ color: "4267B2" }} size={28} />
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a
                href="https://x.com/"
                aria-label="X"
                target="_blank"
              >
                <FaTwitter style={{ color: "00acee" }} size={28} />
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a
                href="https://www.instagram.com/"
                aria-label="Instagram"
                target="_blank"
              >
                <FaInstagram style={{ color: "8a3ab9" }} size={28} />
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a
                href="https://www.youtube.com/"
                aria-label="YouTube"
                target="_blank"
              >
                <FaYoutube style={{ color: "red" }} size={28} />
              </a>
          </Col>
        </Row>
        <br/>
      </Container>
    </footer>
    </section>
  );
}

export default Footer;