import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MyNavbar from './components/MyNavbar';
import Footer from './components/Footer';
import ProductMenuPage from './pages/ProductMenuPage';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import './custom.scss';
import ProductDetailPage from './pages/ProductDetailPage';

function App() {

  return (
    <Router>
      <MyNavbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" exact element={<HomePage />} />
        <Route path="/menu" exact element={<ProductMenuPage />} />
        <Route path="/products" exact element={<ProductMenuPage />} />
        <Route path="/product/:id" element={<ProductDetailPage />} />
        <Route path="/about" exact element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
