import React from 'react';
import { Button, Card } from 'react-bootstrap';
import WhatsAppChat from './WhatsAppChat';

const ProductCard = ({ product }) => {
  const { imageMain, title, price, description } = product;

  return (
    <Card>
      <Card.Img variant="top" src={imageMain} alt={title} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Subtitle>{description}</Card.Subtitle>
        <Card.Text>₹{price}</Card.Text>
        <WhatsAppChat phone="9949096910" imageSrc="./assets/whatsapp.webp" message={title}/>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;